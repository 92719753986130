<template>
  <div>
    <c-search-box @enter="search">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            default="today"
            type="year"
            label="년도"
            name="year"
            v-model="searchParam.year"
          />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-facility
            type="search"
            name="facilityCd"
            v-model="searchParam.facilityCd">
          </c-facility>
        </div> -->
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="시설 자체점검 목록"
      tableId="facilitySelfCheck"
      :columns="grid.columns"
      :data="grid.data"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      @linkClick="linkClick"
    >
      <template slot="suffixTitle">
        <font style="font-size:0.8em;font-weight:300;">
          <q-icon name="done" class="text-positive"/> : 점검완료 &nbsp;&nbsp;
          <q-icon name="build" class="text-orange"/> : 점검중 &nbsp;&nbsp;
          <q-icon name="error" class="text-red"/> : 금주 미진행 &nbsp;&nbsp;
          <q-icon name="close" class="text-red"/> : 미진행 &nbsp;&nbsp;
        </font>
      </template>
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="search" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="!isNaN(props.row[col.name])">
          <template v-if="Number(props.row[col.name]) === 1">
            <q-icon 
              :name="props.row['complete' + col.label] === 'Y' ? 'done' : 'build'" 
              :class="props.row['complete' + col.label] === 'Y' ? 'text-positive' : 'text-orange'"
              class="cursor-pointer" 
              @click="openPop(props.row, col, 1)" />
          </template>
          <template v-else-if="Number(props.row[col.name]) > 1">
            <q-btn
              class="tableinnerBtn"
              flat
              :align="col.align"
              color="blue-6"
              :label="props.row[col.name]"
              @click.stop="openPop(props.row, col, 2)" />
          </template>
          <template v-else-if="Number(props.row[col.name]) === 0">
            <template v-if="col.name === 'weeks' + curWeeks">
              <q-icon name="error" class="text-red cursor-pointer" @click="openPop(props.row, col, 0)" />
            </template>
            <template v-else-if="Number(col.label) < curWeeks || curWeeks === 0">
              <q-icon name="close" class="text-red cursor-pointer" @click="openPop(props.row, col, 0)" />
            </template>
          </template>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'hazard-facility-self-check',
  data() {
    return {
      searchParam: {
        plantCd: null,
        year: null,
        facilityCd: null,
      },
      year: '',
      grid: {
        columns: [],
        data: [],
      },
      popupOptions: {
        isFull: true,
        // width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      editable: true,
      weeksUrl: '',
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    curWeeks() {
      if (this.year === this.$comm.getThisYear()) {
        return this.$comm.moment().week()
      } else {
        return 0;
      }
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.weeksUrl = selectConfig.com.weeks.list.url
      this.listUrl = selectConfig.env.facilitySelfCheck.weeks.url
      // code setting
      // list setting
      this.search()
    },
    search() {
      this.setColumns()
      this.getList();
    },
    getList() {
      if (!this.searchParam.year) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: 
          // item.equipmentNm + 
          '년도를 선택하세요.', // 점검년도를 선택하세요
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.year = this.searchParam.year
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    setColumns() {
      this.$http.url = this.weeksUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        year: this.searchParam.year
      };
      this.$http.request((_result) => {
        let _columns = [
          {
            fix: true,
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            fix: true,
            name: 'facilityName',
            field: 'facilityName',
            label: '시설명',
            align: 'left',
            style: 'width:200px',
            type: 'link',
            sortable: false,
          },
        ]
        if (_result.data && _result.data.length > 0) {

          let months = [];
          this.$_.forEach(this.$_.uniq(this.$_.map(_result.data, 'month')), month => {
            let weeks = [];
            let _width = 0;
            this.$_.forEach(this.$_.filter(_result.data, { month: month }), week => {
              weeks.push({
                name: 'weeks' + week.weeks,
                field: 'weeks' + week.weeks,
                label:  week.weeks,
                align: 'center',
                style: 'width:50px',
                type: 'custom',
                headerColor: week.weeks === String(this.curWeeks) ? '#FFCDD2' : '',
                sortable: false,
              })
              _width += 50;
            })
            months.push({
              name: 'month' + month,
              field: 'month' + month,
              label: month + '월',
              style: 'width:' + _width + 'px',
              child: weeks
            })
          });

          
          this.grid.columns = this.$_.concat(_columns, {
            name: 'year',
            field: 'year',
            label: this.searchParam.year + '년',
            child: months
          });
        }

        if (this.curWeeks > 0) {
          setTimeout(() => {
            let table = this.$refs['table'].$refs['compo-table'].$children[7].$el;
            let tr = this.$refs['table'].$refs['ctr'][2];
            if (tr) {
              this.$set(table, 'scrollLeft', 300 + 50*this.curWeeks - table.offsetWidth)
            }
          }, 100);
        }
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = '시설 상세'; // 시설 상세
      this.popupOptions.param = {
        facilityCd: row ? row.facilityCd : '',
      };
      this.popupOptions.target = () => import(`${'./hazardFacilityDetail.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    openPop(row, col, count) {
      /**
       * count가 0인 경우 자체점검 등록하는 팝업을
       *         1인 경우 자체점검 key값을 찾은 후 팝업을
       *         2인 경우 자체점검 key값들을 찾은 후 자체점검 목록을 보여주는 팝업을
       */
      if (count === 0) {
        this.popupOptions.title = '시설 자체점검 상세'; // 시설 자체점검 상세
        this.popupOptions.param = {
          hcfHazardFacilitySelfCheckId: '',
          facilityCd: row.facilityCd,
          selfCheckDate: Number(col.label) - this.curWeeks !== 0 ? 
            this.$comm.moment().weekday(7 * (Number(col.label) - this.curWeeks)).format('YYYY-MM-DD')
            : this.$comm.getToday()
        };
        this.popupOptions.target = () => import(`${'./hazardFacilitySelfCheckDetail.vue'}`);
        this.popupOptions.isFull = true;
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeCheckPopup;
      } else if (count === 1) {
        this.popupOptions.title = '시설 자체점검 상세'; // 시설 자체점검 상세
        this.popupOptions.param = {
          hcfHazardFacilitySelfCheckId: row['id' + col.label],
        };
        this.popupOptions.target = () => import(`${'./hazardFacilitySelfCheckDetail.vue'}`);
        this.popupOptions.isFull = true;
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeCheckPopup;
      } else {
        this.popupOptions.title = '시설 자체점검'; // 시설 자체점검
        this.popupOptions.param = {
          hcfHazardFacilitySelfCheckIds: row['id' + col.label],
        };
        this.popupOptions.target = () => import(`${'./hazardFacilitySelfCheckWeeksPop.vue'}`);
        this.popupOptions.isFull = false;
        this.popupOptions.width = '70%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeCheckPopup;
      }
    },
    closeCheckPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
